import React from 'react';
import { graphql } from "gatsby";

import Layout from '../../../components/common/layout';

// import Articles from '../../../components/articles/Articles';
// import Header from '../components/common/Header';


const ArtistConvosPage = ({location, data, lang}) => (
  <Layout location={location} lang={lang}>

    <div id="wrapper">
			<section id="main" className="wrapper">
			<header className="page-header">
					<div className="heading">
          <h1 className="major">Artist Conversations</h1>
					</div>
				</header>
				<div className="text-3xl transform skew-x-5 font-serif">
					{/* <Articles articles={data.natureintransitionJson.articles} /> */}
        </div>
      </section>
    </div>

  </Layout>
);

export default ArtistConvosPage;


export const ArtistConvosQuery = graphql`
  query ArtistConvosQuery {
		allMarkdownRemark(
			filter: {frontmatter: {path: {glob: "**/nature-in-transition/articles/**"}}}
		) {
			edges {
				node {
					id
					frontmatter {
						path
						slug
						title
						name
						country
						langKey
					}
					html
					rawMarkdownBody
				}
			}
		}
		natureintransitionJson {
			articles {
				title
				author
				authorTitle
				authorOrg
				to {
					title
					href
				}
			}
		}
  }
`;
